import React, { useCallback, useEffect } from 'react';
import { Button, Input, InputNumber, Form, Typography, Space, Row, Col, Card, Slider } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import __ from '../../../app/i18n';
import { useSelector } from 'react-redux';
import { selectInteractionCallTypes } from '../../team/interactionCallType/slice';

const { Title } = Typography;

export const GenericScore = ({ score, onSave, index, callType }) => {
  const [form] = Form.useForm();
  const callTypes = useSelector(selectInteractionCallTypes);
  
  const displayName = callType === 'Default' ? 'Default' : 
    (callTypes.find(ct => ct._id === callType)?.name || callType);
  
  useEffect(() => {
    if (score) {
      form.setFieldsValue({
        evaluables: score.evaluables || [{
          categoryName: '',
          items: []
        }],
        description: score.description || ''
      });
    }
  }, [score, form]);

  const handleFormChange = useCallback((changedValues, allValues) => {
    const updatedScore = {
      ...score,
      callType,
      description: allValues.description,
      evaluables: allValues.evaluables?.map(category => ({
        ...category,
        items: category.items?.map(item => ({
          ...item,
          weight: Number(item.weight)
        })) || []
      })) || [{
        categoryName: '',
        items: []
      }]
    };
    
    onSave(updatedScore, index);
  }, [score, callType, onSave, index]);

  const handleAddEvaluable = () => {
    const evaluables = form.getFieldValue('evaluables') || [];
    form.setFieldsValue({
      evaluables: [...evaluables, {
        categoryName: '',
        items: []
      }]
    });
    handleFormChange(null, form.getFieldsValue());
  };

  const handleRemoveEvaluable = (evaluableIndex) => {
    const evaluables = form.getFieldValue('evaluables') || [];    
    const newEvaluables = evaluables.filter((_, index) => index !== evaluableIndex);
    form.setFieldsValue({ evaluables: newEvaluables });
    handleFormChange(null, { ...form.getFieldsValue(), evaluables: newEvaluables });
  };

  const handleAddItem = (evaluableIndex) => {
    const evaluables = form.getFieldValue('evaluables') || [];
    const newEvaluables = [...evaluables];
    if (!newEvaluables[evaluableIndex].items) {
      newEvaluables[evaluableIndex].items = [];
    }
    newEvaluables[evaluableIndex].items.push({
      evaluable: '',
      weight: 0
    });
    form.setFieldsValue({ evaluables: newEvaluables });
    handleFormChange(null, form.getFieldsValue());
  };

  const handleRemoveItem = (evaluableIndex, itemIndex) => {
    const evaluables = form.getFieldValue('evaluables') || [];
    const newEvaluables = [...evaluables];
    newEvaluables[evaluableIndex].items = newEvaluables[evaluableIndex].items.filter(
      (_, index) => index !== itemIndex
    );
    form.setFieldsValue({ evaluables: newEvaluables });
    handleFormChange(null, form.getFieldsValue());
  };

  const handleWeightChange = (value, evaluableIndex, itemIndex) => {
    const evaluables = form.getFieldValue('evaluables');
    evaluables[evaluableIndex].items[itemIndex].weight = Number(value);
    form.setFieldsValue({ evaluables });
    handleFormChange(null, form.getFieldsValue());
  };

  return (
    <div style={{ width: '100%' }}>
      <Space direction="vertical" size="large" style={{ display: 'flex', width: '100%' }}>
        <Card style={{ borderRadius: '8px', width: '100%' }}>
          <Space direction="vertical" size="large" style={{ display: 'flex' }}>
            <Title level={4}>
              {__('apps.customScore.idealCall') + ' ' + displayName + '?'}
            </Title>
            <Form
              form={form}
              initialValues={{ 
                evaluables: score?.evaluables || [{
                  categoryName: '',
                  items: []
                }],
                description: score?.description || ''
              }}
              onValuesChange={handleFormChange}
              layout="vertical"
              style={{ width: '100%' }}
            >
              <Form.Item
                name="description"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ width: '100%' }}
              >
                <Input.TextArea 
                  rows={4}
                  placeholder={__('apps.customScore.describeCall') + ' ' + displayName + '.'}
                />
              </Form.Item>

              <Form.List name="evaluables">
                {(evaluables, { add, remove }) => (
                  <Space direction="vertical" size="middle" style={{ display: 'flex', width: '100%' }}>
                    {evaluables.map((evaluable, evaluableIndex) => (
                      <Card key={evaluable.key} style={{ borderColor: '#595959', borderRadius: '8px', width: '100%' }}>
                        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                          <Row align="middle">
                            <Col flex="auto">
                              <Form.Item
                                {...evaluable}
                                name={[evaluable.name, "categoryName"]}
                                required
                                rules={[{ required: true, message: __('apps.customScore.categoryNameRequired') }]}
                                style={{ marginBottom: 0 }}
                              >
                                <Input 
                                  placeholder={__('apps.customScore.categoryNamePlaceholder')}
                                  size="large"
                                  style={{ fontSize: '20px' }}
                                  suffix={
                                      <DeleteOutlined 
                                        onClick={() => handleRemoveEvaluable(evaluableIndex)}
                                        style={{ color: '#262626', cursor: 'pointer', fontSize: '16px' }}
                                      />
                                    
                                  }
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Form.List name={[evaluable.name, "items"]}>
                            {(items, { add: addItem }) => (
                              <Space direction="vertical" size="middle" style={{ width: '100%', paddingRight: 24 }}>
                                {items.map((item, itemIndex) => (
                                  <Row key={item.key} gutter={[16, 16]} align="middle">
                                    <Col xs={24} md={12} lg={14}>
                                      <Form.Item
                                        {...item}
                                        name={[item.name, "evaluable"]}
                                        required
                                        rules={[{ required: true, message: __('apps.customScore.criteriaRequired') }]}
                                        style={{ marginBottom: 0 }}
                                      >
                                        <Input 
                                          placeholder={__('apps.customScore.criteriaPlaceholder')}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={22} md={10} lg={9}>
                                      <Form.Item
                                        {...item}
                                        name={[item.name, "weight"]}
                                        required
                                        rules={[{ required: true, message: __('apps.customScore.weightRequired') }]}
                                        style={{ marginBottom: 0 }}
                                      >
                                        <div style={{ display: 'flex', gap: '8px', width: '100%' }}>
                                          <Slider 
                                            min={0} 
                                            max={10} 
                                            step={0.1} 
                                            style={{ flex: 1 }}
                                            tooltip={{ formatter: (value) => __('apps.customScore.weightTooltip') + ' ' + value }}
                                            value={form.getFieldValue(['evaluables', evaluable.name, 'items', item.name, 'weight'])}
                                            onChange={(value) => {
                                              handleWeightChange(value, evaluableIndex, itemIndex);
                                            }}
                                          />
                                          <InputNumber
                                            min={0}
                                            max={10}
                                            step={0.1}
                                            style={{ width: 55 }}
                                            controls={false}
                                            value={form.getFieldValue(['evaluables', evaluable.name, 'items', item.name, 'weight'])}
                                            onChange={(value) => {
                                              handleWeightChange(value, evaluableIndex, itemIndex);
                                            }}
                                          />
                                        </div>
                                      </Form.Item>
                                    </Col>
                                    <Col xs={2} md={2} lg={1}>
                                      <DeleteOutlined 
                                        onClick={() => handleRemoveItem(evaluableIndex, itemIndex)}
                                        style={{ color: '#262626', cursor: 'pointer', fontSize: '16px' }}
                                      />
                                    </Col>
                                  </Row>
                                ))}
                                <Button type="dashed" onClick={() => handleAddItem(evaluableIndex)} block>
                                  {__('apps.customScore.addItem')}
                                </Button>
                              </Space>
                            )}
                          </Form.List>
                        </Space>
                      </Card>
                    ))}
                    <Button type="primary" onClick={handleAddEvaluable} block>
                      {__('apps.customScore.addCategory')}
                    </Button>
                  </Space>
                )}
              </Form.List>
            </Form>
          </Space>
        </Card>
      </Space>
    </div>
  );
};

export default GenericScore;
