import { Card, Layout, Menu, Typography } from "antd";
import { useEffect, useState } from "react";
import styles from "./team.module.css";
import __ from "../../app/i18n";
import CallTypeView from "./interactionCallType/index";
import TeamsView from "./teams/index";
import { UsersView } from "./users/Index";
import { useDispatch, useSelector } from "react-redux";
import { loadInteractionCallTypes } from "./interactionCallType/slice";
import { loadTeams } from "./teams/slice";
import Profile from "./general/profile/Index";
import { NotificationsTab } from "./general/notifications/Index";
import { getConfig, selectHasManagerAccess } from "../session/slice";
import { selectActiveTab, setActiveTab } from "./users/slice";
const { Content, Sider } = Layout;


const DEFAULT_SECTION_KEY = "profile";

export default function Team({ defaultSection = DEFAULT_SECTION_KEY }) {
  const dispatch = useDispatch();
  const hasManagerAccess = useSelector(selectHasManagerAccess);
  const activeSection = useSelector(selectActiveTab);
  document.title = `${__("appName")} - ${__("team.label")}`;
  const [collapsed] = useState(false);

  useEffect(() => {
    if (defaultSection) {
      dispatch(setActiveTab(defaultSection));
    }

    dispatch(loadInteractionCallTypes());
    dispatch(loadTeams());
    dispatch(getConfig());
  }, [dispatch]);

  const handleSectionChange = (section) => {
    dispatch(setActiveTab(section));
  };

  const renderContent = () => {
    switch (activeSection) {
      case "profile":
        return <Profile />;
      case "notifications":
        return <NotificationsTab />;
      case "users":
        return <UsersView />;
      case "callTypes":
        return <CallTypeView />;
      case "teams":
        return <TeamsView />;
      default:
        return null;
    }
  };

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          width={250}
          collapsed={collapsed}
          style={{ background: "white" }}
        >
          <Typography className={styles.settingsTitle}>
            {__("team.settings")}
          </Typography>
          <Menu
            mode="inline"
            selectedKeys={[activeSection]}
            defaultOpenKeys={["preferences", "organization"]}
            style={{ borderRight: 0 }}
          >
            <Menu.SubMenu
              key="preferences"
              title={__("team.menu.preferences.label")}
            >
              <Menu.Item
                key="profile"
                onClick={() => handleSectionChange("profile")}
              >
                {__("team.menu.preferences.subMenu.general")}
              </Menu.Item>
              <Menu.Item
                key="notifications"
                onClick={() => handleSectionChange("notifications")}
              >
                {__("team.menu.preferences.subMenu.notifications")}
              </Menu.Item>
            </Menu.SubMenu>
            {hasManagerAccess && (
              <Menu.SubMenu
                key="organization"
                title={__("team.menu.organization.label")}
              >
                <Menu.Item
                  key="users"
                  onClick={() => handleSectionChange("users")}
                >
                  {__("team.users")}
                </Menu.Item>
                <Menu.Item
                  key="teams"
                  onClick={() => handleSectionChange("teams")}
                >
                  {__("team.teams")}
                </Menu.Item>
                <Menu.Item
                  key="calltypes"
                  onClick={() => handleSectionChange("callTypes")}
                >
                  {__("team.callTypes")}
                </Menu.Item>
              </Menu.SubMenu>
            )}
          </Menu>
        </Sider>
        <Layout>
          <Content style={{ padding: "0 25px" }}>{renderContent()}</Content>
        </Layout>
      </Layout>
    </>
  );
}
