import React, { useEffect } from "react";
import Check from "./features/session/Check";
import MeetingList from "./features/meetings/List";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "antd/dist/antd.css";
import "./App.css";
import MeetingDetail from "./features/meeting/Detail";
import ConversationDetail from "./features/conversation/Detail.js";
import TeamIndex from "./features/team/Index";
import Loading from "./features/loading/Index";
import { useSelector } from "react-redux";
import {
  selectGlobalErrors,
  selectGlobalMessages,
  selectGlobalSuccess,
  selectIsLoading,
} from "./features/loading/slice";
import message from "antd/lib/message/index";
import SetPassword from "./features/session/SetPassword";
import Agenda from "./features/agenda/MyNextMeetings";
import Analytics from "./features/analytics/Index";
import Library from "./features/library/index";
import LibraryMeetings from "./features/library/LibraryMeetings";
import Marketplace from "./features/marketplace/List";
import NewIntegration from "./features/marketplace/New";
import Ping from "./features/ping/Index";
import { selectUser } from "./features/session/slice";
import MyNextMeetingsList from "./features/agenda/MyNextMeetings/index";
import Share from "./features/share";
import { ForgotPasswordForm } from "./features/session/forgotPassword/ForgotPasswordForm.js";
import { ForgotPasswordEmailSent } from "./features/session/forgotPassword/ForgotPasswordEmailSent.js";

function App() {
  const isLoading = useSelector(selectIsLoading);
  const messages = useSelector(selectGlobalMessages);
  const success = useSelector(selectGlobalSuccess);
  const errors = useSelector(selectGlobalErrors);
  const user = useSelector(selectUser);

  useEffect(() => {
    if (messages && messages.length) {
      messages.forEach((m) => message.info(m));
    }
  }, [messages]);

  useEffect(() => {
    if (success && success.length) {
      success.forEach((m) => message.success(m));
    }
  }, [success]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.googletagmanager.com/gtag/js?id=G-YCDK5KZB8C';
      document.head.appendChild(script);

      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag(){ window.dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'G-YCDK5KZB8C');
      };

      return () => {
        // Clean up the script to avoid memory leaks when the component is unmounted
        document.head.removeChild(script);
      };
    }

    return () => {};
  }, []);

  useEffect(() => {
    if (user && Array.isArray(window.dataLayer)) {
      window.dataLayer.push({ user_id: user._id });
    }

    let userData = {};
    if (user) {
      userData = {
        user_id: user._id,
        name: user.name + ' ' + user.lastName,
        email: user.email,
        company: user.account
      };

      window.helpcrunchSettings = {
        organization: 'samu',
        appId: 'd91cb00d-5043-4d44-b313-62d4d5156449',
        user: userData
      };

      try {
        (function(w,d){var hS=w.helpcrunchSettings;if(!hS||!hS.organization){return;}var widgetSrc='https://embed.helpcrunch.com/sdk.js';w.HelpCrunch=function(){w.HelpCrunch.q.push(arguments)};w.HelpCrunch.q=[];function r(){if (d.querySelector('script[src="' + widgetSrc + '"')) { return; }var s=d.createElement('script');s.async=1;s.type='text/javascript';s.src=widgetSrc;(d.body||d.head).appendChild(s);}if(d.readyState === 'complete'||hS.loadImmediately){r();} else if(w.attachEvent){w.attachEvent('onload',r)}else{w.addEventListener('load',r,false)}})(window, document);
      } catch (error) {}
    }

  }, [user]);

  useEffect(() => {
    if (errors && errors.length) {
      errors.forEach((m) => message.error(m));
    }
  }, [errors]);

  if (isLoading) return <Loading />;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/set-password/:passwordToken" element={<SetPassword />} />
        <Route path="/forgot-password" element={<ForgotPasswordForm />} />
        <Route path="/forgot-password/email-sent/:email" element={<ForgotPasswordEmailSent />} />
      </Routes>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Check>
            <Ping/>
            <Routes>
              <Route path="/analytics" element={<Analytics />} />
              <Route path="/agenda" element={<Agenda />} />
              <Route path="/scheduled" element={<MyNextMeetingsList />} />
              <Route path="/" element={<MeetingList />} />
              <Route path="/meetings" element={<MeetingList />} />
              <Route path="/settings" element={<TeamIndex />} />
              <Route path="/notifications" element={<TeamIndex defaultSection="notifications" />} />
              <Route path="/meeting/:id" element={<MeetingDetail />} />
              <Route path="/conversation/:id" element={<ConversationDetail />} />
              <Route path="/library/:id" element={<LibraryMeetings />} />
              <Route path="/library" element={<Library />} />
              <Route path="/apps" element={<Marketplace />} />
              <Route path="/apps/:appTypeId" element={<NewIntegration />} />
            </Routes>
          </Check>
          <Routes>
            <Route path="/share/:token" element={<Share />} />
          </Routes>
        </>
      )}
    </BrowserRouter>
  );
}

export default App;
