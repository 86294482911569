import { Progress, Typography } from "antd";
import { Modal } from "antd";
import React, { useMemo, useEffect, useState } from "react";
import { EvaluableCategoryList } from "./EvaluableCategoryList";

import Api from "../../../../app/api";

import styles from "./styles.module.css";
import __ from "../../../../app/i18n";

export const CustomSamuScoreModal = ({ 
  evaluables, 
  score,
  hostId, 
  open, 
  onClose, 
  onUpdateMeetingCustomerScore 
}) => {
  const [editable, setEditable] = useState(false);

  const evaluablesResult = useMemo(() => {
    const result = {
      cantTotalProperties: 0,
      cantPropertiesInTrue: 0,
    };

    (evaluables || []).forEach((evaluable) => {
      evaluable.items.forEach((item) => {
        result.cantTotalProperties++;
        if (item.value) {
          result.cantPropertiesInTrue++;
        }
      });
    });

    return result;
  }, [evaluables]);

  // check if user is manager
  useEffect(() => {
    if (!open) {
      return;
    }

    if (!hostId) {
      return;
    }
    
    Api('isTeamManager', {
      userId: hostId
    }).then((response) => {
      setEditable(!!response);
    });
  }, [hostId, open]);

  return (
    <Modal
      width={1000}
      open={open}
      cancelButtonProps={{ hidden: true }}
      onCancel={onClose}
      footer={false}
    >
      <div className={styles.modalContent}>
        <div className={styles.progressItem}>
          <Typography.Title level={5}>
            {__("meetings.customScore.score")}
          </Typography.Title>
          <Progress type="circle" percent={score * 10} format={x => x / 10} />
        </div>
        <div className={styles.progressItem}>
        <Typography.Title level={5}>
          {__("meetings.customScore.totalItemsFound")}
        </Typography.Title>
        <Progress
          type="circle"
          percent={
            (evaluablesResult.cantPropertiesInTrue * 100) /
            evaluablesResult.cantTotalProperties
          }
          format={() =>
            evaluablesResult.cantPropertiesInTrue +
            "/" +
            evaluablesResult.cantTotalProperties
          }
        />
        </div>
       
        <div className={styles.scoreDetails}>
          <EvaluableCategoryList
            editable={editable}
            onUpdateMeetingCustomerScore={onUpdateMeetingCustomerScore}
            categoryList={evaluables ?? []}
          />
        </div>
      </div>
    </Modal>
  );
};
