import { Tabs } from "antd";
import __ from "../../../app/i18n";
import { SummaryTab } from "./tabs/SummaryTab";
import { renderMapper } from "../../meeting/sections/ai/tabs/utils";
import ChatTab from "../../meeting/sections/ai/tabs/Chat";
import { useSelector } from "react-redux";
import { selectConversation } from "../slice";
import { useMemo } from "react";
import { IconClipboardText } from '@tabler/icons-react';
import styles from "./styles.module.css";
import OpenAISVG from "./OpenAISVG";

export default function Sidebar() {

  const conversation = useSelector(selectConversation);
  const initialTabs = useMemo(() => {
    return [
      {
        label: <div className={styles.tabContainer}>
            <IconClipboardText  className={styles.iconTab} />
                {__("conversation.details.tabs.detailsLabel")}
            </div>,
        content: <SummaryTab />,
      },
      {
        label: <div className={styles.tabContainer}>
          <OpenAISVG />
                {__("meetings.details.sections.ai.tabs.chat")}
            </div>,
        content: <ChatTab interactionId={conversation.id} />,
      },
    ];
  }, [conversation]);
  return (
    <div
      style={{backgroundColor: "white" }}
      className={styles.sidebarContainer}
    >
      <Tabs
        style={{ width: "100%" }}
        defaultActiveKey="1"
        items={renderMapper(initialTabs)}
      />
    </div>
  );
}
