import React from "react";
import { Collapse } from "antd";

import { EvaluableCategoryItem } from "./EvaluableCategoryItem";
import styles from "./styles.module.css";
const { Panel } = Collapse;

export const EvaluableCategoryList = ({ categoryList, editable, onUpdateMeetingCustomerScore }) => {
  return (
    <div>
      {(categoryList || []).map((evaluable, categoryIndex) => (
        <Collapse
          collapsible="header"
          defaultActiveKey={["1"]}
          className={styles.evaluableModalPanel}
          key={evaluable.categoryName}
        >
          <Panel
            header={
              <>
                <span>{evaluable.categoryName}</span>
                <span className={styles.categoryEvaluableScore}>
                  {evaluable.items.filter((i) => i.value).length}/
                  {evaluable.items.length}
                </span>
              </>
            }
            key={`${categoryIndex}_evaluable`}
          >
            {evaluable.items.map((item, evaluableIndex) => (
              <EvaluableCategoryItem 
                onUpdateMeetingCustomerScore={() => onUpdateMeetingCustomerScore({
                  categoryName: evaluable.categoryName,
                  categoryIndex,
                  evaluableIndex,
                  evaluable: item.evaluable,
                  value: !item.value
                })}
                editable={editable}
                item={item} 
                key={evaluableIndex} 
              />
            ))}
          </Panel>
        </Collapse>
      ))}
    </div>
  );
};
