import React, { useState } from "react";
import { Typography, Tooltip } from "antd";
import __ from "../../../../app/i18n";
import { CheckOutlined, CloseOutlined, LoadingOutlined, EditOutlined } from "@ant-design/icons";
import styles from "./styles.module.css";

export const EvaluableCategoryItem = ({ item, editable, onUpdateMeetingCustomerScore }) => {
  const [changingValue, setChangingValue] = useState(false);

  const handleChangingValue = () => {
      setChangingValue(true);
      onUpdateMeetingCustomerScore().finally(() => {
        setChangingValue(false);
      });
  }

  const tooltipText = item.value ? 
    __("meetings.customScore.evaluables.changeToDisapproved") : 
    __("meetings.customScore.evaluables.changeToApproved");

  return (
    <div className={styles.categoryItem}>
      {item.value ? (
        <CheckOutlined className={styles.checkIcon} />
      ) : (
        <CloseOutlined className={styles.closeIcon} />
      )}
      
      {changingValue ? (
        <LoadingOutlined className="pointer"  />
      ) : editable && (
        <Tooltip title={tooltipText}>
          <EditOutlined onClick={handleChangingValue} />
        </Tooltip>
      )}
      
      <Typography.Text className={styles.evaluableText}>
        {item.evaluable}
      </Typography.Text>
    </div>
  );
};
