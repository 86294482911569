import interactionCallTypeMethods from './api/interactionCallTypes.js';
import teamsMethods from './api/teams.js';
const baseUrl = process.env.REACT_APP_API_URL || "https://api.samu.ai";
console.log('Api started.')

const methods = {
  getHosts: {
    path: "/account/users",
    method: "GET",
  },
  getConfig: {
    path: "/account/config",
    method: "GET",
  },
  updateConfig: {
    path: "/account/config",
    method: "POST",
  },
  getMonthlyReport: {
    path: "/account/monthly-report",
    method: "POST",
  },
  getAccountTopics: {
    path: "/account/topics",
    method: "GET",
  },
  getClients: {
    path: "/account/clients",
    method: "GET",
  },
  getCallTypes: {
    path: "/account/calltypes",
    method: "GET",
  },
  createCallType: {
    path: "/account/calltypes",
    method: "POST",
  },
  updateCallType: {
    path: "/account/calltypes",
    method: "PUT",
  },
  ...interactionCallTypeMethods,
  ...teamsMethods,
  getTrackers: {
    path: "/meeting/trackers",
    method: "GET",
  },
  getAnalytics: {
    path: "/account/analytics",
    method: "POST",
  },
  getCustomReportTypes: {
    path: "/account/custom-report-types",
    method: "GET",
  },
  getSpeakers: {
    path: "/account/speakers",
    method: "GET",
  },
  createUser: {
    path: "/account/user/new",
    method: "POST",
  },
  updateUser: {
    path: ({ userId }) => `/account/user/${userId}/update`,
    method: "POST",
  },
  resetPassword: {
    path: ({ userId }) => `/account/user/${userId}/reset-password`,
    method: "POST",
  },
  createMeeting: {
    path: "/meeting",
    method: "POST",
  },
  loadMeeting: {
    path: "/meeting/detail",
    method: "POST",
  },
  loadAi: {
    path: ({ id }) => `/meeting/${id}/ai`,
    method: "GET",
  },
  requestAi: {
    path: ({ id }) => `/meeting/${id}/ai`,
    method: "POST",
  },
  searchMeetings: {
    path: "/meeting/search",
    method: "POST",
  },
  login: {
    path: "/auth/login",
    method: "POST",
  },
  loginWithGoogle: {
    path: "/auth/loginWithGoogle",
    method: "POST",
  },
  loginAsExtension: {
    path: "/auth/extension/login",
    method: "POST",
  },
  deleteUser: {
    path: "/account/user/delete",
    method: "DELETE",
  },
  getMeetingDetails: {
    path: ({ id }) => `/meeting/${id}`,
    method: "POST",
  },
  getConversationDetails: {
    path: ({ id }) => `/conversation/${id}`,
    method: "GET",
  },
  getMeetingAppTabs: {
    path: ({ id }) => `/app/${id}/tabs`,
    method: "POST",
  },
  getAppFilters: {
    path: () => `/app/filters`,
    method: "GET",
  },
  requestAppInstallation: {
    path: ({ appType }) => `/app/request/${appType}`,
    method: "GET",
  },
  joinToMeeting: {
    path: () => `/meeting/join`,
    method: "POST",
  },
  deleteMeeting: {
    path: ({ id }) => `/meeting/${id}`,
    method: "DELETE",
  },
  updateMeetingSyncStatus: {
    path: ({ id }) => `/me/meetings/${id}/status`,
    method: "POST",
  },
  toggleUserAccess: {
    path: () => `/account/user/access`,
    method: "POST",
  },
  googleConnections: {
    path: () => `/account/google-connections`,
    method: "GET",
  },
  cancelMeeting: {
    path: ({ id }) => `/meeting/${id}/cancel`,
    method: "POST",
  },
  chatMeeting: {
    path: ({ interactionId }) => `/meeting/${interactionId}/chat`,
    method: "POST",
  },
  addComment: {
    path: ({ id }) => `/meeting/${id}/comment`,
    method: "POST",
  },
  getComments: {
    path: ({ id }) => `/meeting/${id}/comments`,
    method: "GET",
  },
  getSharedMeeting: {
    path: ({ token }) => `/share/meeting/${token}`,
    method: "GET",
  },
  getShareableUrl: {
    path: ({ id }) => `/meeting/share/${id}`,
    method: "GET",
  },
  updateShareableUrl: {
    path: ({ id }) => `/meeting/share/${id}`,
    method: "POST",
  },
  getMyMeetings: {
    path: `/me/meetings`,
    method: "POST",
  },
  loadNotifications: {
    path: `/me/notifications`,
    method: "GET",
  },
  notificationSeen: {
    path: `/me/notifications`,
    method: "POST",
  },
  changePassword: {
    path: `/me/change-password`,
    method: "POST",
  },
  resetPasswordByEmail: {
    path: `/user/reset-password`,
    method: "POST",
  },
  getTeamMeetings: {
    path: `/meeting/team`,
    method: "POST",
  },
  setPassword: {
    path: "/password",
    method: "POST",
  },
  getGoogleLoginData: {
    path: "/me/integrations/google",
    method: "GET",
  },
  getMicrosoftLoginData: {
    path: "/me/integrations/microsoft",
    method: "GET",
  },
  disconnectGoogle: {
    path: "/me/integrations/google/disconnect",
    method: "POST",
  },
  disconnectMicrosoft: {
    path: "/me/integrations/microsoft/disconnect",
    method: "POST",
  },
  createLibrary: {
    path: () => `/library`,
    method: "POST",
  },
  getLibraries: {
    path: () => `/library/`,
    method: "GET",
  },
  getApps: {
    path: () => `/app`,
    method: "GET",
  },
  getAppForm: {
    path: ({ appTypeId }) => `/app/${appTypeId}/form`,
    method: "GET",
  },
  sendWebhookTest: {
    path: () => `/app/webhook-test`,
    method: "POST",
  },
  saveAppSettings: {
    path: ({ appId }) => `/app/${appId}/form`,
    method: "POST",
  },
  getMeetingsByLibrary: {
    path: (libraryId) => `/library/${libraryId}/meetings`,
    method: "GET",
  },
  getLibrariesByMeeting: {
    path: (meetingId) => `/library/${meetingId}/libraries`,
    method: "GET",
  },
  updateLibrariesToMeeting: {
    path: (libraryId) => `/library/${libraryId}/meetings`,
    method: "POST",
  },
  deleteLibrary: {
    path: ({ libraryId }) => `/library/${libraryId}`,
    method: "DELETE",
  },
  removeMeetingFromLibrary: {
    path: (libraryId) => `/library/${libraryId}/meetings`,
    method: "DELETE",
  },
  markAsSeen: {
    path: ({ id }) => `/meeting/${id}/seen`,
    method: "POST",
  },
  updatePrivacy: {
    path: ({ id }) => `/meeting/${id}/privacy`,
    method: "POST",
  },
  updateLang: {
    path: ({ id }) => `/meeting/${id}/lang`,
    method: "POST",
  },
  generateAIReport: {
    path: ({ id, reportType }) => `/meeting/${id}/ai-report/${reportType}`,
    method: "POST",
  },
  getAiChats: {
    path: ({ interactionId }) => `/meeting/${interactionId}/ai-chats`,
    method: "GET",
  },
  getAIReport: {
    path: ({ id, reportType }) => `/meeting/${id}/ai-report/${reportType}`,
    method: "GET",
  },
  getTags: {
    path: () => `/meeting/tags`,
    method: "GET",
  },
  updateTags: {
    path: ({ meetingId }) => `/meeting/${meetingId}/tags`,
    method: "POST",
  },
  updateHost: {
    path: ({ meetingId }) => `/meeting/${meetingId}/host`,
    method: "POST",
  },
  updateMeetingName: {
    path: ({ meetingId }) => `/meeting/${meetingId}/name`,
    method: "POST",
  },
  updateMeetingClient: {
    path: ({ meetingId }) => `/meeting/${meetingId}/client`,
    method: "POST",
  },
  updateMeetingCustomerScore: {
    path: ({ meetingId }) => `/meeting/${meetingId}/customScore`,
    method: 'POST',
  },
  createTag: {
    path: () => `/meeting/tag`,
    method: "POST",
  },
  getMeetingScores: {
    path: ({ callType }) => `/meeting/last-meetings/${callType}`,
    method: "GET",
  },
  testCustomScore: {
    path: ({ id }) => `/meeting/${id}/score`,
    method: "POST",
  },
};

export default async function Api(key, payload, options = {}) {
  const method = methods[key];

  let path = method.path;
  if (typeof path === 'function') {
    path = path(payload);
  }

  /** @type RequestInit */
  const request = {
    method: method.method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  }

  if (method.method !== 'GET') {
    request.body = JSON.stringify(payload);
  }

  try {
    const url = new URL(path, method?.baseUrl || baseUrl);
    const response = await fetch(url.toString(), request);

    if (response.status === 401) {
      onLogout();
      document.location.reload();
      return;
    }

    const data = await response.json();
    if (!['login', 'loginAsExtension', 'loginWithGoogle'].includes(key) && response.status >= 400) {
      throw Object.assign(
        new Error(`Error ${response.status} calling ${request.method || 'GET'} ${key}`),
        { data }
      )
    }

    return data;
  } catch (error) {
    if (['login', 'loginAsExtension', 'loginWithGoogle'].includes(key)) {
      return { error: error.message };
    }

    if (options.revert) {
      throw error;
    }
  }
}

export function isLoggedIn() {
  const isLoggedIn = !!localStorage.getItem("samu:loggedIn");

  return isLoggedIn;
}

export function setToken(token, user) {
  localStorage.setItem("samu:token", token);
  localStorage.setItem("samu:loggedIn", true);
  localStorage.setItem("samu:user", JSON.stringify(user));
}

export function getToken() {
  return localStorage.getItem("samu:token");
}

export function getUser() {
  return JSON.parse(localStorage.getItem("samu:user"));
}

export function updateUserLang(lang) {
  const user = JSON.parse(localStorage.getItem("samu:user"));
  user.lang = lang;
  localStorage.setItem("samu:user", JSON.stringify(user));
}

export function onLogout() {
  localStorage.removeItem('samu:token');
  localStorage.removeItem('samu:loggedin');
  localStorage.removeItem('samu:user');
}