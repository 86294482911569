import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../../app/api';
import { getRandomColors } from '../../app/color';
import __ from '../../app/i18n';
import { getSecondsDiff } from '../../app/utils';
import getObjProperty from 'lodash/get';
import {
  showGlobalErrors,
  showGlobalSuccess,
  startLoading,
  stopLoading,
} from "../loading/slice";

const initialState = {
  status: 'iddle',
  meeting: {},
  openDealSection: null,
  openTeamSection: null,
  showCC: false,
  videoTime: 0,
  transcription: [],
  participants: [],
  videoDuration: 0,
  currentDisplayingMessage: null,
  deleted: false,
  meetingTabs: []
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(searchmeeting(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const loadMeeting = createAsyncThunk(
  'meeting/fetchMeeting',
  async (id) => {
    const response = await API('getMeetingDetails', { id });

    return response;
  }
);

export const loadMeetingAppTabs = createAsyncThunk(
  'meeting/fetchMeetingAppTabs',
  async (id) => {
    const response = await API('getMeetingAppTabs', { id });

    return response;
  }
);

export const markAsSeen = createAsyncThunk(
  'meeting/markAsSeen',
  async (id) => {
    const response = await API('markAsSeen', { id })

    return response;
  }
);

export const deleteMeeting = createAsyncThunk(
  "team/deleteMeeting",
  async (payload, { dispatch }) => {
    dispatch(startLoading());

    const response = await API("deleteMeeting", payload);
    if (response && response.errors) {
      dispatch(showGlobalErrors(response.errors));
    } else {
      dispatch(showGlobalSuccess([__("generic.alerts.meetingDeleted")]));
    }

    dispatch(stopLoading());

    return response;
  }
);

export const updateCallType = createAsyncThunk(
  'meeting/updateCallType',
  async (payload, { dispatch }) => {
    try {
      await API('updateInteractionCallType', {
        id: payload.meetingId,
        callType: payload.callTypeId,
        interactionId: payload.interactionId
      });
      dispatch(loadMeetingAppTabs(payload.meetingId));
      dispatch(loadMeeting(payload.meetingId));
      return payload.callTypeName;
    } catch (error) {
      throw error;
    }
  }
);

export const meetingSlice = createSlice({
  name: 'meeting',
  initialState,
  reducers: {
    openDealSection: (state, action) => {
      state.openDealSection = action.payload;
    },
    updateCurrentMeetingTags: (state, action) => {
      if (state.meeting && state.meeting.id === action.payload.meetingId) {
        state.meeting.tags = action.payload.tags;
      }
    },
    closeDealSection: (state) => {
      state.openDealSection = null;
    },
    openTeamSection: (state, action) => {
      state.openTeamSection = action.payload;
    },
    closeTeamSection: (state) => {
      state.openTeamSection = null;
    },
    stopLoading: (state) => {
      state.status = 'iddle';
    },
    toggleCC: (state) => {
      state.showCC = !state.showCC;
    },
    moveVideo: (state, action) => {
      state.videoTime = action.payload;
    },
    setVideoDuration: (state, action) => {
      state.videoDuration = action.payload;
    },
    setCurrentDisplayingMessage: (state, action) => {
      state.currentDisplayingMessage = action.payload;
    },
    clearDeleted: (state, action) => {
      state.deleted = false;
    },
    updateMeetingCallType: (state, action) => {
      if (state.meeting) {
        state.meeting.callType = action.payload;
      }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(loadMeeting.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadMeeting.fulfilled, (state, action) => {
        if (action && (!action.payload || (action.payload && !Object.keys(action.payload).length))) {
          state.status = 'idle';
          state.deleted = true;
          state.participants = [];
          state.transcription = [];
        } else {
          state.meeting = action.payload;
          state.status = 'idle';
          const participants = Array.from(
            new Set((state.meeting.transcription || []).map((m) => m.who))
          );
          const participantsColors = getRandomColors(participants.length);
          state.participants = participants.map((p, index) => ({ name: p, color: participantsColors[index]}));
  
  
          state.transcription = (state.meeting.transcription || []).map((t) => {
            const timestampStart =
              Math.max(getSecondsDiff(new Date(state.meeting.dateFrom), new Date(t.startAt)) - 5, 0);
            const timestampStop = getSecondsDiff(
              new Date(state.meeting.dateFrom),
              new Date(t.endAt)
            );
        
            return {
              ...t,
              timestampStart,
              timestampStop,
            };
          });
        }
      })

      .addCase(loadMeetingAppTabs.fulfilled, (state, action) => {
        state.meetingTabs = action.payload ?? [];
      })
  },
});

export const { openDealSection, closeDealSection,  openTeamSection, closeTeamSection, toggleCC, moveVideo, setVideoDuration, setCurrentDisplayingMessage, clearDeleted, updateCurrentMeetingTags, updateMeetingCallType } = meetingSlice.actions;
export const selectMeeting = (state) => state.meeting.meeting;
export const selectMeetingMinute = (state) => state.meeting.meeting?.minute;
export const selectLastVisits = (state) => getObjProperty(state, 'meeting.meeting.metrics.visits', []);
export const selectIsFetching = (state) => state.meeting.status === 'loading';
export const selectOpenDealSection = (state) => state.meeting.openDealSection;
export const selectOpenTeamSection = (state) => state.meeting.openTeamSection;
export const selectCC = (state) => state.meeting.showCC;
export const selectVideoTime = (state) => state.meeting.videoTime;
export const selectParticipants = (state) => state.meeting.participants;
export const selectTranscription = (state) => state.meeting.transcription;
export const selectVideoDuration = (state) => state.meeting.videoDuration;
export const selectCurrentDisplayingMessage = (state) => state.meeting.currentDisplayingMessage;
export const selectIsDeleted = (state) => state.meeting.deleted;
export const selectMeetingTabs = (state) => state.meeting.meetingTabs;

export default meetingSlice.reducer;
