const methods = {
    getIcallTypes: {
      path: "/account/icalltypes",
      method: "GET",
    },
    postIcallTypes: {
      path: "/account/icalltypes",
      method: "POST",
    },
    putIcallTypes: {
      path: "/account/icalltypes",
      method: "PUT",
    },
    deleteICallTypes: {
      path: ({id}) => `/account/icalltypes/${id}`,
      method: 'DELETE'
    },
    updateInteractionCallType: {
      path: ({ id }) => `/account/icalltypes/${id}/updatemeeting`,
      method: "POST",
    },
}
export default methods;