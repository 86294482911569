import React, { useCallback } from "react";
import { GenericScore } from "./GenericScore";

const CustomScoreForm = ({ scores = [], onSave, activeKey }) => {
  const handleSaveScore = useCallback(
    (updatedScore, index) => {
      const newScores = scores.map((score, idx) => {
        if (idx === index) {
          return {
            ...score,
            ...updatedScore
          };
        }
        return score;
      });
      onSave(newScores);
    },
    [scores, onSave]
  );

  const activeScore = scores.find(s => s.callType === activeKey);

  if (!activeScore) return null;
  
  return (
    <div style={{ width: '100%' }}>
      <GenericScore
        callType={activeScore.callType}
        score={activeScore}
        onSave={(updatedScore) => 
          handleSaveScore(
            updatedScore, 
            scores.findIndex(s => s.callType === activeScore.callType)
          )
        }
        key={activeScore.callType}
      />
    </div>
  );
};

export default CustomScoreForm;