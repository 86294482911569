import { Form, Input, Button, Tooltip } from "antd";
import { useState } from "react";
import AppItem from "../AppItem";
import __ from "../../../app/i18n";
import { sendWebhookTest } from "../slice";
import { useDispatch } from "react-redux";
import { message } from "antd";

function App(props) {
  const dispatch = useDispatch();
  const [url, setUrl] = useState(props.app.url);
  const [enabled, setEnabled] = useState(props.app.enabled);
  const [disableSave, setDisableSave] = useState(true);

  const handleSave = () => {
    if (!disableSave) {
      return;
    }

    const newSettings = {
      ...props.app,
      enabled,
      url,
    };

    props.handleOnSave(newSettings);
  };

  const handleTest = async () => {
    const result = await dispatch(sendWebhookTest({ url })).unwrap();
    if (result) {
      setDisableSave(false);
      handleSave();
    } else {
      setDisableSave(true);
    }
  };

  const renderSaveButton = () => (
    <Button
      disabled={disableSave}
      type="primary"
      style={{
        marginTop: "1rem",
        paddingLeft: "2.5rem",
        paddingRight: "2.5rem",
      }}
      onClick={handleSave}
    >
      {__("apps.extractor.buttons.save")}
    </Button>
  );

  return (
    <AppItem
      app={props.app}
      onToggleStatus={(e) => setEnabled(e)}
      canSave={false}
      onSave={handleSave}
      canToggleStatus={props.app}
      enabled={enabled}
      saveTooltip={"a"}
    >
      <Form>
        <Input
          style={{
            display: "inline-block",
            width: "auto",
            minWidth: "550px",
            marginRight: "10px",
          }}
          onChange={(e) => setUrl(e.target.value)}
          value={url}
        />
        <Button
          type="submit"
          style={{ display: "inline-block" }}
          onClick={handleTest}
        >
          {__("apps.webhook.sendTest")}
        </Button>
      </Form>
      {disableSave ? (
        <Tooltip title={__("apps.webhook.saveDisabled")}>{renderSaveButton()}</Tooltip>
      ) : (
        renderSaveButton()
      )}
    </AppItem>
  );
}

export default App;
