import { Drawer, Button, Form, Input, Select, Checkbox } from "antd";
import { useState, useEffect } from "react";
import __ from "../../../app/i18n";
import { updateInteractionCallType } from "./slice";
import { useSelector, useDispatch } from "react-redux";
import { selectTeams } from "../teams/slice";

export default function EditCallType({ open, onClose, callType }) {
    const teams = useSelector(selectTeams);
    const dispatch = useDispatch();

    const [selectAll, setSelectAll] = useState(false);
    const [selectedTeams, setSelectedTeams] = useState([]);

    useEffect(() => {
        if (callType) {
            setSelectedTeams(callType.teams);
            setSelectAll(callType.teams.length === teams.length);
            
        }
    }, [callType, teams]);
    if (!open || !callType) return null;

    const handleSelectAllChange = (e) => {
        const isChecked = e.target.checked;
        setSelectAll(isChecked);
        setSelectedTeams(isChecked ? teams.map(team => team._id) : []);
    };

    const handleEditCallType = (values) => {
        const params = {
            name: values.name,
            description: values.description,
            teams: selectAll ? teams.map(team => team._id) : selectedTeams,
        };
        dispatch(updateInteractionCallType({ ...params, id: callType._id }));
        onClose();
    };

    return (
        <Drawer
            title={__("team.editCallType")}
            zIndex={999}
            placement={"right"}
            width={500}
            onClose={onClose}
            open={open}
            getContainer={true}
        >
            <Form
                initialValues={
                    {teams: callType.teams}
                }
                name="editCallType"
                layout="vertical"
                onFinish={handleEditCallType}
                autoComplete="off"
            >
                <Form.Item
                    name="name"
                    label={__("generic.fields.name")}
                    initialValue={callType.name}
                    rules={[{ required: true, message: __("generic.required.name") }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="description"
                    label={__("team.newCallType.description")}
                    initialValue={callType.description}
                    rules={[{ required: true, message: __("team.newCallType.required_description") }]}
                >
                    <Input.TextArea rows={8} placeholder={__("team.newCallType.label_description")} />
                </Form.Item>

                <Form.Item style={{ marginBottom: '2px' }}>
                    <Checkbox checked={selectAll} onChange={handleSelectAllChange}>
                        {__("team.newCallType.selectAllTeams")}
                    </Checkbox>
                </Form.Item>

                {!selectAll && (
                    <Form.Item
                        name="teams"
                        label={__("team.newCallType.selectTeams")}
                        rules={[{ required: !selectAll, message: __("team.newCallType.required_teams") }]}
                    >
                        <Select 
                            
                            mode="multiple" 
                            value={selectedTeams}
                            disabled={selectAll}
                            onChange={(value) => setSelectedTeams(value)}
                        >
                            {teams.map(team => (
                                <Select.Option key={team._id} value={team._id}>
                                    {team.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                )}

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {__("meetings.filters.save")}
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    );
}
