import { Divider, List } from "antd";
import Section from "../../meeting/sections/Section";
import { fixGPTEncoding } from "../../../app/utils";

export const prettyLabel = label => decodeURIComponent(label).replace(/_/gi, ' ');

const firstUp = (str) => {
  let result = "-";
  if (str) {
    try {
      result = str[0].toUpperCase() + str.substring(1, str.length);
    } catch (error) {
      result = "-";
    }
  }
  return result;
}

const Properties = (data) => {
  data = Object.keys(data).map((key) => ({ label: prettyLabel(key), value: data[key] }));

  return (
    <List
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(k) => (
        <List.Item>
          <List.Item.Meta
            title={firstUp(k.label)}
            description={firstUp(fixGPTEncoding(String(k.value)))}
          />
        </List.Item>
      )}
    />
  );
};

export default function Tab({ data }) {
  const properties = data.properties;

  return (
    <Section key={data.id} sectionKey={data.id} title={data.name}>
      {properties ? (
        <Properties {...properties} />
      ) : (
        "No hay información"
      )}
      <Divider />
    </Section>
  );
}
