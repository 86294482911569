import { Button, Form, Typography } from "antd";
import _ from "lodash";
import { useState } from "react";
import AppItem from "../AppItem";
import Section from "./Inputs/Section";
import __ from "../../../app/i18n";
import { WarningOutlined } from "@ant-design/icons";
function NotLoggedInForm(props) {
  return (
    <AppItem app={props.app} canSave={false} canToggleStatus={false}>
      <Button>
        <a href={props.app.loginUrl}>Conectar</a>
      </Button>
    </AppItem>
  );
}

function DisconnectButton({ url }) {
  return (
    <>
      <Typography.Title level={5} style={{ marginTop: "15px" }}>Gestión de acceso</Typography.Title>
      <Button type="danger" style={{ marginBottom: "15px" }}>
        <a href={url}>
          <WarningOutlined /> {" " + __("apps.generic.disconnect")}
        </a>
      </Button>
    </>
  );
}

function App(props) {
  const [enabled, setEnabled] = useState(props.app.enabled);
  const [sections, setSections] = useState(props.app.sections || []);

  if (!props.app.isLoggedIn && props.app.loginUrl) {
    return <NotLoggedInForm {...props} />;
  }

  const handleSave = () => {
    const newSettings = {
      ...props.app,
      enabled,
      sections,
    };

    props.handleOnSave(newSettings);
  };

  const handleUpdateProperty = (sectionName, propertyName, propertyValue) => {
    const newSections = [...sections];
    const newSection = sections.find((s) => s.name === sectionName);
    const newProperties = [...newSection.properties].map((p) => {
      const obj = { ...p };
      if (obj.name === propertyName) {
        obj.value = propertyValue;
      }

      return obj;
    });

    const index = newSections.findIndex((s) => s.name === newSection.name);
    newSections[index] = { ...newSection, properties: newProperties };

    setSections(newSections);
  };

  return (
    <AppItem
      app={props.app}
      onToggleStatus={(e) => setEnabled(e)}
      onSave={handleSave}
      canSave={
        !Object.prototype.hasOwnProperty.call(props.app, "isLoggedIn") ||
        props.app.isLoggedIn
      }
      canToggleStatus={
        !Object.prototype.hasOwnProperty.call(props.app, "isLoggedIn") ||
        props.app.isLoggedIn
      }
      enabled={enabled}
    >
      {Array.isArray(sections) && sections.length ? (
        sections.map((s) => (
          <Section onUpdateProperty={handleUpdateProperty} {...s} />
        ))
      ) : (
        <></>
      )}
      {props.app.disconnectUrl && (
        <DisconnectButton url={props.app.disconnectUrl} />
      )}
      <Form />
    </AppItem>
  );
}

export default App;
