import { Select, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import __ from "../../../../app/i18n";
import { removeAccents } from "../../../../app/utils";
import {
  selectedInteractionCallTypes,
  updateInteractionCallTypesSelection,
} from "../slice";
import { selectInteractionCallTypes } from "../../../team/interactionCallType/slice";

export default function ClientFilter() {
  const allInteractionCallTypes = useSelector(selectInteractionCallTypes);
  const appliedInteractionCallTypes = useSelector(selectedInteractionCallTypes);
  const dispatch = useDispatch();

  const options = (allInteractionCallTypes || []).map((item, index) => ({
    key: `interactionCallType#${index}`,
    label: item.name,
    value: item._id,
  })).sort((a, b) => a.label <= b.label ? -1 : 1);

  const handleOnChange = (interactionCallTypes) => {
    dispatch(updateInteractionCallTypesSelection(interactionCallTypes));
  };
  const selectProps = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    options,
    value: appliedInteractionCallTypes,
    onChange: (e) => handleOnChange(e),
    placeholder: __("meetings.filters.selectCallType"),
    showArrow: true,
    filterOption: (typed, item) => {
      const contains = String(item.label)
        .toUpperCase()
        .startsWith(String(removeAccents(typed)).toUpperCase());
      return contains;
    },
  };

  return (
    <Space
      direction="vertical"
      style={{
        width: "calc(100% - 30px)",
        margin: "15px",
      }}
    >
      <Select {...selectProps} />
    </Space>
  );
}
