import { Select } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectedAppFilters } from "../../meetings/filters/slice";
import { removeAccents } from "../../../app/utils";

export default function OptionFilter({
  appName,
  appId,
  label,
  optionKey,
  onUpdate,
  options,
  groupOption,
  extra
}) {
  const appFilters = useSelector(selectedAppFilters);
  let defaultValue = null;
  try {
    defaultValue = appFilters[appId].values[optionKey];
  } catch (error) {}

  const [value, setValue] = useState(defaultValue);

  const handleChange = (v) => {
    setValue(v);
    onUpdate(optionKey, v);
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const selectOptions = [];
  const ordered = [...options]
    .sort((a, b) => (a.label < b.label ? -1 : 1))
    .filter((option, index, self) => 
      index === self.findIndex((o) => (
        o.id === option.id && o.label === option.label
      ))
    );

  if (groupOption) {
    const groups = [...new Set(ordered.map(o => o[groupOption]))];

    for (const group of groups) {
      selectOptions.push({
        label: group,
        options: ordered
          .filter(o => o[groupOption] === group)
          .map(o => ({ 
            value: o.id || o.label,
            label: o.label 
          }))
      })
    }
  } else {
    for (const o of ordered) {
      selectOptions.push({ 
        value: o.id || o.label,
        label: o.label 
      });
    }
  }

  return (
    <div>
      <div style={{ padding: "20px 0 20px", fontWeight: "initial" }}>
        {label} {extra}
      </div>

      <Select
        showSearch
        allowClear={true}
        style={{
          display: "inline-block",
          width: "100%",
        }}
        value={value}
        onChange={handleChange}
        options={selectOptions}
        filterOption={(input, option) => removeAccents(String(option?.label ?? "").toLowerCase()).includes(removeAccents(String(input).toLowerCase()))}
      />
    </div>
  );
}
