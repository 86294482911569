import { useState } from "react";
import AppItem from "../AppItem";
import { CallTypeTabs } from "./CallTypeTabs";

function App(props) {
  const [enabled, setEnabled] = useState(props.app.enabled);

  const getExtractors = () => {
    if (props.app.defaultExtractor) {
      return [
        { ...props.app.defaultExtractor, callType: { name: "Default" } },
        ...props.app.callTypeExtractors,
      ];
    } else {
      const defaultExtractor = {
        callType: {
          name: "Default",
        },
        properties: [],
      };
      return [defaultExtractor, ...(props.app.callTypeExtractors || [])];
    }
  };

  const handleSave = (callTypeExtractors, enabledValue) => {
    let newSettings = { ...props.app, enabled: enabledValue };
    if (callTypeExtractors?.length) {
      newSettings = {
        ...newSettings,
        callTypeExtractors: callTypeExtractors.filter(
          (extractor) => extractor.callType.name !== "Default"
        ),
        defaultExtractor: callTypeExtractors.find(
          (extractor) => extractor.callType.name === "Default"
        ),
      };
    }
    props.handleOnSave(newSettings);
  };

  return (
    <AppItem
      app={props.app}
      onToggleStatus={(e) => {
        setEnabled(e);
        handleSave(null, e);
      }}
      canSave={false}
      onSave={() => {}}
      canToggleStatus={props.app}
      enabled={enabled}
    >
      <CallTypeTabs onSave={handleSave} extractors={getExtractors()} />
    </AppItem>
  );
}

export default App;
