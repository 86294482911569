import { useState } from "react";
import { Layout, Row, Col, Card, Space } from "antd";
import AppItem from "../AppItem";
import CustomScoreTab from "./CustomScoreTab";
import { ScoreValidation } from "./ScoreValidation";
import CallTypesTabs from "./CallTypesTabs";
import { useDispatch } from "react-redux";
import { loadInteractionCallTypes } from "../../team/interactionCallType/slice";
import { useEffect } from "react";

const normalizeScores = (scores) => {
  return scores.map(score => ({
    ...score,
    evaluables: score.evaluables?.map(evaluable => ({
      ...evaluable,
      items: evaluable.items?.map(item => ({
        ...item,
        weight: Number(item.weight || 0)
      })) || []
    })) || []
  }));
};

function App(props) {
  const getCustomScores = () => {
    const scores = [];
    scores.push({
      callType: 'Default',
      description: props.app?.defaultCallTypeCustomScore?.description || '',
      evaluables: props.app?.defaultCallTypeCustomScore?.evaluables || []
    });
    props.app?.callTypeCustomScores?.forEach(customScore => {
      scores.push(customScore);
    });
    return normalizeScores(scores);
  };
  const dispatch = useDispatch();
  const initialScores = getCustomScores();
  const [enabled, setEnabled] = useState(props.app?.enabled || false);
  const [activeScores, setActiveScores] = useState(initialScores);
  const [activeCallType, setActiveCallType] = useState(initialScores[0]?.callType);
  const [activeKey, setActiveKey] = useState(initialScores[0]?.callType);
  const handleSave = (currentScore, enabledValue) => {
    let newSettings = { ...props.app, enabled: enabledValue };
    
    if (currentScore) {
      const normalizedScore = normalizeScores([currentScore])[0];
      if(normalizedScore?.callType === 'Default'){
        newSettings = {
          ...newSettings,
          defaultCallTypeCustomScore: normalizedScore
        };
      } else {
        newSettings = {
          ...newSettings,
          callTypeCustomScores: [normalizedScore]
        };
      }
    }
    console.log(newSettings);
    props.handleOnSave(newSettings);
  };

  const handleScoreUpdate = (scores) => {
    setActiveScores(scores);
  };


  useEffect(() => {
    dispatch(loadInteractionCallTypes());
  }, []);

  if (!props.app) {
    return null;
  }

  return (
    <AppItem
      app={props.app}
      onToggleStatus={(e) => {
        setEnabled(e);
        handleSave(null, e);
      }}
      canSave={false}
      onSave={() => {}}
      canToggleStatus={true}
      enabled={enabled}
    >
      <Layout style={{ background: 'transparent', width: '100%' }}>
        <Row style={{ width: '100%', margin: 0 }}>
          <Col xs={24} md={6}>
            <CallTypesTabs 
              scores={activeScores}
              onCallTypeChange={setActiveCallType}
              activeKey={activeKey}
              onActiveKeyChange={setActiveKey}
            />
          </Col>
          <Col xs={24} md={12} style={{ 
            padding: '0 1rem', 
            borderTop: '1px solid #f0f0f0', 
            paddingTop: '1rem'
          }}>
            <CustomScoreTab 
              onSave={handleScoreUpdate}
              scores={activeScores}
              activeKey={activeKey}
              customScores={initialScores}
            />
          </Col>
          <Col xs={24} md={6} style={{
            borderTop: '1px solid #f0f0f0',
            paddingTop: '1rem'
          }}>
            <ScoreValidation 
              appprops={props}
              scores={activeScores}
              onSave={(scores) => handleSave(scores)}
              currentCallType={activeCallType}
            />
          </Col>
        </Row>
      </Layout>
    </AppItem>
  );
}

export default App;



