import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "./Header";
import {
  clearConversationData,
  loadConversation,
  selectIsLoading,
  selectConversation,
} from "./slice";
import { useDispatch, useSelector } from "react-redux";
import Chat from "./Chat";
import { Col, Row } from "antd";
import Loading from "../loading/Index";
import { Typography } from "antd";
import __ from "../../app/i18n";
import styles from "./styles.module.css";
import { IconAlertTriangle } from '@tabler/icons-react';
import Sidebar from "./Sidebar/Sidebar"
const { Title } = Typography;

export default function Detail(props) {
  const dispatch = useDispatch();
  const { id } = useParams("id");
  const loading = useSelector(selectIsLoading);
  const conversation = useSelector(selectConversation);

  useEffect(() => {
    // Fetch conversation
    dispatch(loadConversation(id));

    return () => {
      dispatch(clearConversationData());
    };
  }, [id]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {Object.keys(conversation).length ? (
        <div>
          <Header />
          <Row style={{ minHeight: "100%" }}>
            <Col md={7}>
              <Sidebar />
            </Col>

            <Col
              md={17}
              style={{
                overflowY: "scroll",
                padding: "15px",
              }}
            >
              <Chat />
            </Col>
          </Row>
        </div>
      ) : (
        <div
          className={styles.conversationNotFoundContainer}
        >
          <IconAlertTriangle stroke={1.5} size={'150'} />
          <Title level={3} className={styles.conversationNotFoundTitle}>
            {__("conversation.conversationNotFoundMessage")}
          </Title>
        </div>
      )}
    </>
  );
}
