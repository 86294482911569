import { Tabs, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectInteractionCallTypes } from "../../team/interactionCallType/slice";

const CallTypesTabs = ({ 
  scores = [], 
  onCallTypeChange, 
  activeKey, 
  onActiveKeyChange,
}) => {
  const [tabPosition, setTabPosition] = useState("left");
  const callTypes = useSelector(selectInteractionCallTypes);

  useEffect(() => {
    const handleResize = () => {
      setTabPosition(window.innerWidth <= 680 ? "top" : "left");
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getTabLabel = (callType) => {
    if (callType === 'Default') return 'DEFAULT';
    const callTypeInfo = callTypes.find(ct => ct._id === callType);
    return (callTypeInfo?.name)?.toUpperCase();
  };

  const callTypeTabs = scores.map((score) => ({
    key: score.callType,
    label: <Typography.Text>{getTabLabel(score.callType)}</Typography.Text>,
    children: <div style={{borderLeft: '1px solid #f0f0f0'}}/>
  }));

  return (
    <Tabs
      className="customScoreTabsContainer"
      style={{ width: "100%", height: '100%', borderTop: '1px solid #f0f0f0', display: "block" }}
      tabPosition={tabPosition}
      type="card"
      activeKey={activeKey}
      onChange={(newKey) => {
        onActiveKeyChange?.(newKey);
        onCallTypeChange(newKey);
      }}
      items={callTypeTabs}
    />
  );
};

export default CallTypesTabs; 