import { Tabs } from "antd";
import BaseMethodologySalesSummaryTab from "./BaseMethodologySalesSummaryTab";
import { useState } from "react";
import { renderMapper } from "./utils";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../session/slice";

const initialTabs = [
    {
        label: "SPICED",
        content: <BaseMethodologySalesSummaryTab methodologyName='Spiced' reportType='spiced' />,
    },
    {
        label: "BANT",
        content: <BaseMethodologySalesSummaryTab methodologyName='Bant' reportType='bant' />,
    },
    {
        label: "SANDLER",
        content: <BaseMethodologySalesSummaryTab methodologyName='Sandler' reportType='sandler' />,
    },
    {
        label: "SPIN SELLING",
        content: <BaseMethodologySalesSummaryTab methodologyName='Spin Selling' reportType='spinSellingTN' />,
    }
]

export const BaseMethodologySalesSummaryContainer = () => {
    const user = useSelector(selectUser);
    const [tabs] = useState(renderMapper(initialTabs.filter(t => t.label !== 'SPIN SELLING' || (t.label === 'SPIN SELLING' && user.account === '669e9d83158c019dc33ffb43'))));

    return (<Tabs
        type="card"
        defaultActiveKey="1"
        items={tabs}
    />
    );
}