import React, { useCallback, useState } from "react";
import Property from "./Property";
import { Button, Switch, Typography, notification, Space } from "antd";
import { IconDeviceFloppy, IconPlus } from "@tabler/icons-react";
import { v4 as uuidv4 } from "uuid";
import styles from "./styles.module.css";
import __ from "../../../app/i18n";
import { CloseCircleTwoTone } from "@ant-design/icons";

const stickyHeaderStyle = {
  position: 'sticky',
  top: 0,
  zIndex: 1,
  backgroundColor: '#fff',
  borderBottom: '3px solid #f0f0f0',
  display: 'flex',
  alignItems: 'flex-start',
  gap: '1rem',
  // borderBottom: "2px solid #ddd",
  padding: "10px 15px",
};

export const GenericExtractor = ({ extractor, onSave, index }) => {
  const [api, contextHolder] = notification.useNotification();

  const [extractorState, setExtractorState] = useState({
    ...extractor,
    properties: extractor.properties?.map((p) => ({ ...p, id: uuidv4() })),
  });

  const handleNew = useCallback(() => {
    setExtractorState((prevState) => ({
      ...prevState,
      properties: [
        {
          name: "",
          description: "",
          type: "uniqueOption",
          values: [],
          id: uuidv4(),
        },
        ...prevState.properties,
      ],
    }));
  }, []);

  const onRemove = (id) => {
    setExtractorState((prevState) => {
      return {
        ...prevState,
        properties: prevState.properties.filter(
          (property) => property.id !== id
        ),
      };
    });
  };

  const onUpdateProperty = ({ index, ...prop }) => {
    const updatedProperties = [...extractorState.properties];
    updatedProperties[index] = prop;
    const extractorUpdated = {
      ...extractorState,
      properties: updatedProperties,
    };
    setExtractorState(extractorUpdated);
  };

  const onEnableExtractor = () => {
    onSave({ ...extractorState, enabled: !extractorState.enabled }, index);
    setExtractorState((prevState) => ({
      ...prevState,
      enabled: !prevState.enabled,
    }));
  };

  const handleSaveExtractor = () => {
    const hasErrors = extractorState.properties.some(p => p.errors && Object.keys(p.errors).length > 0)
    if(!hasErrors) {
      const extractor = {
        ...extractorState,
        properties: extractorState?.properties.map(({ errors, ...p }) => p),
      }

      onSave(extractor, index)
    } else {
      api.info({
        icon: <CloseCircleTwoTone />,
        message: __("apps.extractor.alerts.cantNotSave"),
        description:
          __("apps.extractor.alerts.checkErrors"),
        placement: "top",
      });
    }
  }

  return (
    <div>
      {contextHolder}
      <div style={stickyHeaderStyle}>
          <Typography.Title style={{ margin: 0 }} level={4}>
            {__('callType.key')} "{extractor?.name}"
          </Typography.Title>
          <Space>
            <Button
              type="primary"
              icon={<IconDeviceFloppy size="20px" stroke={2} />}
              disabled={!extractorState.enabled}
              onClick={handleSaveExtractor}
              style={{ display: 'flex', alignItems: 'center'}}
            >
              {__("apps.extractor.buttons.save")}
            </Button>
            <Button
              type="primary"
              icon={<IconPlus size="20px" stroke={2} />}
              disabled={!extractorState.enabled}
              onClick={handleNew}
              style={{ display: 'flex', alignItems: 'center'}}
            >
              {__("apps.extractor.buttons.add")}
            </Button>
          </Space>
        <Switch checked={extractorState.enabled} onChange={onEnableExtractor} />
      </div>
      {extractorState?.enabled &&
        extractorState.properties?.map((p, i) => (
          <Property
            {...p}
            index={i}
            key={p.id}
            onUpdateProperty={onUpdateProperty}
            onRemove={onRemove}
          />
        ))}
    </div>
  );
};
